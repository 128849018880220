import * as React from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactUs from "../components/contactus";

const hrStyle = {
    margin: "60px auto"
}

const imgStyle = {
    borderRadius: "5%"
}

const SIP_jpg = {
    margin: "50px auto"
}

const tableStyle = {
    borderCollapse: "collapse",
    border: "solid 4px #333"
}

const thtdStyle = {
    border: "solid 2px #333"
}

const leftTdStyle = {
    padding: "10px",
}

const not_space = {
    margin : "0px",
}

const FreeVoicePage = () => (
    <Layout>
        <Seo title="フリーボイス"/>
        <div align="center">

            <hr style={hrStyle}/>

            <h1 id="sip_trunk">フリーボイス</h1>
            <div>
                <p style={not_space}>フリーダイヤルより通話料金が安い</p>
            </div>

            <hr style={hrStyle}/>

            <div>
                <h1>フリーボイスとは？</h1>
                <h2>特徴</h2>

                <div>
                    <StaticImage src="../images/products/freevoice/freevoice3.png"
                                 alt={"コール・インテリジェンス3"}
                                 style={imgStyle}
                                 width={600}/>
                </div>
            </div>
            <br/>
            <div>
                <StaticImage src="../images/products/freevoice/freevoice2.png"
                             alt={"コール・インテリジェンス2"}
                             style={imgStyle}
                             width={600}/>
            </div>

            <hr style={hrStyle}/>

            <div>
                <h2>事前にご準備いただくもの</h2>
                <h3>フリーボイスをご利用いただく場合は下記のいずれかの着信回線が必要となります</h3>

            </div>

            <div>
                <StaticImage src="../images/products/freevoice/freevoice4.png"
                             alt={"コール・インテリジェンス4"}
                             style={imgStyle}
                             width={600}/>
            </div>

            <hr style={hrStyle}/>


            <ContactUs/>
        </div>
    </Layout>
)

export default FreeVoicePage